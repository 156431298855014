import { createGlobalStyle, css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  ${({ theme: { colors, fonts } }) => `
    body {
      color: ${parseColor(colors.textDefault)};
      font-family: ${fonts.default}, sans-serif;
      line-height: 1;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
    }

    a,
    a[href^="tel:"] {
      transition: all ease 0.4s;
      color: ${parseColor(colors.primary)};
      &:hover {
        color: ${parseColor(colors.amy400)};
      }
    }
  `}

  .player {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
      border-radius: 8px;
      overflow: hidden;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  ${media.lessThan('xs')} {
    .swiper-partial {
      .swiper-slide {
        width: 95% !important;
      }
    }
  }

  .section-rounded {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      width: 120vw;
      height: 150px;
      border-radius: 100vw / 10vw;
      position: absolute;
      bottom: -102px;
      left: -10vw;
      right: -10vw;
    }

    ${({ theme: { colors } }) => css`
      &:after {
        background-color: ${parseColor(colors.trooper25)};
      }
    `}
  }
`

export default GlobalStyle
