import React, { PropsWithChildren } from 'react'
import { WindowLocation } from '@reach/router'
import { ThemeProvider } from '@superlogica/design-ui'

import vectors from 'svg/vectors'
import { theme, GlobalStyle } from 'styles'

interface WrapPageElementProps {
  location?: WindowLocation<unknown>
}

const WrapPageElement = ({
  children
}: PropsWithChildren<WrapPageElementProps>) => (
  <ThemeProvider theme={theme} svgLibrary={{ vectors }}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default WrapPageElement
