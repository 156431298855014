import React, {
  Context,
  createContext,
  PropsWithChildren,
  useState
} from 'react'

import { appConfig } from 'config'

export type Config = Record<string, any>

export const ConfigContext: Context<Config> = createContext<Config>({})

interface ConfigProviderProps {}

export const ConfigProvider = ({
  children
}: PropsWithChildren<ConfigProviderProps>) => {
  const [config, setConfig] = useState<Config>(appConfig)

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  )
}
