/* eslint-disable prettier/prettier */
import { ReactComponent as ArrowDown } from './arrow-down.svg'
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { ReactComponent as ArrowUp } from './arrow-up.svg'
import { ReactComponent as Building1 } from './building-1.svg'
import { ReactComponent as DoubleCheck } from './double-check.svg'
import { ReactComponent as Gruvi } from './gruvi.svg'
import { ReactComponent as InterfaceDeleteCircle } from './interface-delete-circle.svg'
import { ReactComponent as InterfaceValidationCheckCircle } from './interface-validation-check-circle.svg'
import { ReactComponent as LogoCyrela } from './logo-cyrela.svg'
import { ReactComponent as LogoIntelbras } from './logo-intelbras.svg'
import { ReactComponent as LogoSuperlogica } from './logo-superlogica.svg'
import { ReactComponent as MultipleUsers } from './multiple-users.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as SingleNeutralActionsHome } from './single-neutral-actions-home.svg'
import { ReactComponent as SocialFacebook } from './social-facebook.svg'
import { ReactComponent as SocialInstagram } from './social-instagram.svg'
import { ReactComponent as SocialLinkedin } from './social-linkedin.svg'
import { ReactComponent as SocialSpotify } from './social-spotify.svg'
import { ReactComponent as SocialTiktok } from './social-tiktok.svg'
import { ReactComponent as SocialTwitter } from './social-twitter.svg'
import { ReactComponent as SocialYoutube } from './social-youtube.svg'
import { ReactComponent as Waves } from './waves.svg'

const vectors = {
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'building-1': Building1,
  'double-check': DoubleCheck,
  'gruvi': Gruvi,
  'interface-delete-circle': InterfaceDeleteCircle,
  'interface-validation-check-circle': InterfaceValidationCheckCircle,
  'logo-cyrela': LogoCyrela,
  'logo-intelbras': LogoIntelbras,
  'logo-superlogica': LogoSuperlogica,
  'multiple-users': MultipleUsers,
  'play': Play,
  'single-neutral-actions-home': SingleNeutralActionsHome,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-spotify': SocialSpotify,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-youtube': SocialYoutube,
  'waves': Waves,
}

export type VectorsProps = keyof typeof vectors

export default vectors